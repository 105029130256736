<template>
  <div class="content-wrapper">
    <div class="d-flex justify-content-between">
      <h1 class="mb-5">Lista rilevazioni</h1>
      <div style="font-size: 1.5rem">
        <b-badge variant="warning">
          <b-icon-star-fill class="mr-2"></b-icon-star-fill> Plugin
        </b-badge>
      </div>
    </div>

    <b-card class="mb-4">
      <b-row>
        <b-col cols="12">
          <div
            class="d-100 d-flex justify-content-between align-items-center mb-4"
          >
            <div>
              <b-button @click="$router.go(-1)" size="sm"
                ><b-icon icon="chevron-left" aria-hidden="true"></b-icon> Torna
                indietro</b-button
              >
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                size="sm"
                class="mb-2 mr-2"
                v-b-toggle.collapse-advancedFilter
                :disabled="advancedFilter_visible"
              >
                <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced
                Filter
              </b-button>
              <b-button
                :disabled="loading"
                @click="generaDocumentoDiDossier"
                size="sm"
                class="mb-2 mr-2"
              >
                <b-icon icon="plus" aria-hidden="true"></b-icon>
                {{
                  loading
                    ? "Generando il documento... "
                    : "Genera documento di Dossier"
                }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col size="12"
          ><div>
            <b-form-checkbox v-model="toggleVista" name="check-button" switch>
              Espandi
            </b-form-checkbox>
          </div></b-col
        >
      </b-row>
    </b-card>
    <b-collapse
      style="overflow: visible"
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card style="overflow: visible" class="mb-4">
        <div class="d-flex">
          <div class="flex flex-column">
            <h4>Advanced Filter</h4>
            <span style="font-style: italic; color: cadetblue;"
              >Tieni premuti i tasti <span class="font-bold">"ctrl"</span> o
              <span class="font-bold">"shift"</span> per selezionare più
              elementi di un filtro</span
            >
          </div>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col cols="3" class="mt-4">
            <label>Ordine di servizio</label>
            <b-form-select
              multiple
              v-model="advancedFilter.ordine"
              :options="ordineOptions"
            ></b-form-select>
          </b-col>
          <b-col cols="3" class="mt-4">
            <label>Luogo</label>
            <b-form-select
              multiple
              v-model="advancedFilter.luogoInizio"
              :options="computed_luoghi"
            ></b-form-select>
          </b-col>
          <b-col cols="3" class="mt-4">
            <label>CIE</label>
            <b-form-select
              multiple
              v-model="advancedFilter.CIE"
              :options="computed_cieOptions"
            ></b-form-select>
          </b-col>
          <b-col cols="3" class="mt-4">
            <label>Stato rilevazione</label>
            <b-form-select
              multiple
              v-model="advancedFilter.stato"
              :options="[
                { value: 2, text: 'Pubblica' },
                { value: 1, text: 'Interna' },
                { value: 0, text: 'Esculsa' },
              ]"
            ></b-form-select>
          </b-col>
          <b-col cols="3" class="mt-4">
            <label>Da Data</label>
            <b-form-datepicker
              :locale="'it'"
              v-model="advancedFilter.DaData"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col cols="3" class="flex flex-column mt-4">
            <label>Da Ora</label>
            <vue-timepicker
              class="custom-timepicker"
              advanced-keyboard
              manual-input
              v-model="advancedFilter.DaOra"
            ></vue-timepicker>
          </b-col>
          <b-col cols="3" class="mt-4">
            <label>A Data</label>
            <b-form-datepicker
              :locale="'it'"
              v-model="advancedFilter.AData"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col cols="3" class="flex flex-column mt-4">
            <label>A Ora</label>
            <vue-timepicker
              class="custom-timepicker"
              advanced-keyboard
              manual-input
              v-model="advancedFilter.AOra"
            ></vue-timepicker>
          </b-col>
          <b-col cols="3" class="mt-4">
            <label>Indagato</label>
            <b-form-select
              multiple
              v-model="advancedFilter.indagato"
              :options="computed_indagatoOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-4">
          <b-col cols="auto">
            <b-button
              style="align-self: right"
              @click="cancellaFiltri"
              class="mr-2"
              >Pulisci filtri</b-button
            >
            <b-button style="align-self: right" @click="applicaFiltri"
              >Filtra</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-overlay :show="loading" rounded="sm" class="overlay-placeholder">
      <b-card v-if="dossier">
        <div class="d-flex flex-column justify-content-between mb-2">
          <div>
            <h2>
              <span v-if="dossier.codice">{{ dossier.codice }} -</span>
              {{ dossier.titolo }}
            </h2>
          </div>
          <div>
            <div v-html="dossier.descrizione"></div>
          </div>
        </div>
        <span style="color: gray">{{ dossier.annotazioni }}</span>
        <hr />

        <div
          @click="tmpItem = item"
          v-b-toggle.sidebar-rilevazione
          class="mb-4 axerta-card-rilevazione-reduced"
          v-for="item of filtered_items"
          :key="item.id"
          :style="borderStato(item.stato)"
        >
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div
              v-if="item.elementiMultimediali.length > 0"
              class="anteprima-rilevazione"
            >
              <img :src="item.elementiMultimediali[0].urlElemento" />
            </div>
            <div class="flex-grow">
              <div
                v-if="item.descrizioneHistory.length > 0"
                class="d-flex justify-content-between header mb-4"
              >
                <span v-if="item.descrizioneHistory[0].testo">{{
                  item.descrizioneHistory[0].testo
                }}</span>
              </div>

              <div class="d-flex justify-content-end text-muted flex-wrap">
                <span class="mr-4">
                  <b-icon-folder-fill class="mr-2"></b-icon-folder-fill
                  >{{ item.codiceOrdineDiServizio || "---" }}
                </span>
                <span class="mr-4">
                  <b-icon-map class="mr-2"></b-icon-map
                  >{{ item.luogoInizio || "---" }}
                </span>
                <span class="mr-4">
                  <b-icon-calendar2-week-fill
                    class="mr-2"
                  ></b-icon-calendar2-week-fill
                  ><span>
                    {{ formatDateTime(item.dataOraInizio) }}
                  </span>
                </span>
                <span class="mr-4">
                  <b-icon-person-fill class="mr-2"></b-icon-person-fill
                  >{{ item.CieRegistrato }}
                </span>
                <span class="mr-4">
                  <b-icon-person-bounding-box
                    class="mr-2"
                  ></b-icon-person-bounding-box>
                  {{ item.indagato }}
                </span>
                <span class="mr-4">
                  <b-icon-camera-fill class="mr-2"></b-icon-camera-fill
                  >{{ item.elementiMultimediali.length }}
                </span>
                <span :style="colorStato(item.stato)">
                  <b-icon-circle-fill></b-icon-circle-fill>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <b-card class="mt-4 mb-4">
        <div>
          <b-form-checkbox v-model="toggleVista" name="check-button" switch>
            Espandi
          </b-form-checkbox>
        </div>
      </b-card>
    </b-overlay>

    <b-sidebar
      class="right-sidebar"
      id="sidebar-rilevazione"
      right
      shadow
      lazy
      @hidden="resetData"
      @shown="sidebarRilevazioneVisible = true"
      :visible="sidebarRilevazioneVisible"
    >
      <SingolaRilevazioneDettagliata
        @closeSidebar="closeSidebar"
        :item="tmpItem"
      ></SingolaRilevazioneDettagliata>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService";
import SingolaRilevazioneDettagliata from "@/views/axerta/SingolaRilevazioneDettagliata";

export default {
  name: "ListaRilevazioni",
  components: {
    SingolaRilevazioneDettagliata,
  },
  async created() {
    this.idDossier = this.$route.params.idDossier;
    await this.getRilevazioni();
    await this.getDossier();
    // this.getcomputed_cieOptions();
    await this.getOrdineOptions();

    this.applicaFiltri();
  },

  data() {
    return {
      toggleVista: false,
      loading: false,
      advancedFilter: {
        CIE: [],
        ordine: [],
        DaData: new Date().toISOString().split("T")[0],
        DaOra: {
          HH: "00",
          mm: "00",
        },
        AData: new Date().toISOString().split("T")[0],
        AOra: {
          HH: "23",
          mm: "59",
        },
        stato: [],
        luogoInizio: [],
        indagato: [],
      },

      ordineOptions: [],

      sidebarRilevazioneVisible: false,
      advancedFilter_visible: false,
      show: false,
      currentImg: "",
      filter: "",
      idDossier: null,
      currentItem: null,
      tmpItem: {
        id: null,
        title: "",
        luogo_inizio: "",
        confermato: false,
        agente: "",
        data_suggerita: "",
        ora_suggerita: "",
        sospettati: null,
        annotazioni: "",
      },

      items: [],
      filtered_items: [],
      descrizioneHistoryVisible: false,
      nuovaDescrizione: "",
      dossier: null,
    };
  },
  methods: {
    generaDocumentoDiDossier() {
      this.loading = true;

      const filtro = {
        CIE: this.advancedFilter.CIE,
        ordine: this.advancedFilter.ordine,
        DaData:
          this.advancedFilter.DaData +
          "T" +
          this.advancedFilter.DaOra.HH +
          ":" +
          this.advancedFilter.DaOra.mm,
        AData:
          this.advancedFilter.AData +
          "T" +
          this.advancedFilter.AOra.HH +
          ":" +
          this.advancedFilter.AOra.mm,
        stato: this.advancedFilter.stato,
        luogoInizio: this.advancedFilter.luogoInizio,
        indagato: this.advancedFilter.indagato,
      };

      const service = new AxiosService(
        "DossierManager/CreateDossierFile/" + this.idDossier
      );
      service
        .create(filtro)
        .then((res) => {
          window.open("https://api-axerta.whappy.it/" + res, "_blank").focus();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    colorStato(idStato) {
      if (idStato == 0) return "color:firebrick;";
      if (idStato == 1) return "color:orange;";
      if (idStato == 2) return "color:forestgreen;";
      if (idStato == 3) return "color:purple";
    },
    borderStato(idStato) {
      if (idStato == 0) return "border:2px solid firebrick;";
      if (idStato == 1) return "border:2px solid orange;";
      if (idStato == 2) return "border:2px solid forestgreen;";
      if (idStato == 3) return "border:2px solid purple;";
    },
    // getcomputed_cieOptions() {
    //   const service = new AxiosService("User");
    //   service.read().then((res) => {
    //     res.forEach((element) => {
    //       this.computed_cieOptions.push({
    //         value: element.id,
    //         text: element.nome + " " + element.cognome,
    //       });
    //     });
    //   });
    // },
    async getOrdineOptions() {
      this.ordineOptions.splice(0);

      const service = new AxiosService(
        "DossierManager/GetOrdiniDiServizio/" + this.idDossier
      );
      await service.read().then((res) => {
        res.forEach((element) => {
          this.ordineOptions.push({
            value: element.id,
            text: element.codice + " - " + element.titolo,
          });
        });
      });
    },
    closeSidebar() {
      this.sidebarRilevazioneVisible = false;
      this.getRilevazioni();
    },
    async resetData() {
      this.tmpItem = {};
      await this.getRilevazioni();
      await this.getDossier();
      // this.getcomputed_cieOptions();
      await this.getOrdineOptions();

      this.applicaFiltri();
    },
    formatDateTime(string) {
      return (
        new Date(string).toLocaleDateString("it") +
        " " +
        new Date(string).toLocaleTimeString("it", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    },
    openOrdineDiServizio() {
      this.$router.push(
        "/axerta/ordine-di-servizio/" + this.$route.params.idDossier
      );
    },
    setCurrentImg(img) {
      this.currentImg = {};
      this.currentImg = img;
    },
    async getDossier() {
      const service = new AxiosService(
        "DossierManager/GetDossier/" + this.idDossier
      );
      await service
        .read()
        .then((res) => {
          this.dossier = res;
        })
        .finally(() => {
          this.advancedFilter.DaData = new Date(this.dossier.dataInizio)
            .toISOString()
            .split("T")[0];
        });
    },
    async getRilevazioni() {
      this.loading = true;
      this.items.splice(0);
      const service = new AxiosService(
        "DossierManager/GetRilevazioniDettaglio/" + this.idDossier + "/0"
      );
      await service
        .read()
        .then((res) => {
          res.map((x) => (x.editMode = false));
          this.items = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setClassStato(id) {
      let imgClass = "";
      switch (id) {
        case 0:
          imgClass = "Interna";
          break;
        case 1:
          imgClass = "Esclusa";
          break;
        case 2:
          imgClass = "Pubblicazione";
          break;
        case 3:
          imgClass = "DaAnalizzare";
          break;
      }
      return imgClass;
    },
    showDescrizioneHistory() {
      this.descrizioneHistoryVisible = true;
    },
    hideDescrizioneHistory() {
      this.descrizioneHistoryVisible = false;
    },
    salvaDescrizione(item) {
      const service = new AxiosService(
        "DossierManager/EditDescrizioneRilevazione/" + item.id
      );
      const nuovaDescrizioneObj = {
        descrizione: this.nuovaDescrizione,
        elementiMultimediali: [],
      };

      service
        .create(nuovaDescrizioneObj)
        .then((res) => console.log(res))
        .finally(() => {
          this.descrizioneHistoryVisible = false;
          item.editMode = false;
        });
    },
    cancellaFiltri() {
      this.advancedFilter = {
        CIE: [],
        ordine: [],
        DaData: new Date(this.dossier.dataInizio).toISOString().split("T")[0],
        DaOra: {
          HH: "00",
          mm: "00",
        },
        AData: new Date().toISOString().split("T")[0],
        AOra: {
          HH: "23",
          mm: "59",
        },
        stato: [],
        luogoInizio: [],
        indagato: [],
      };
    },

    applicaFiltri() {
      const filteredItems = this.items.filter((item) => {
        // Filtering based on CIE
        if (
          this.advancedFilter.CIE.length > 0 &&
          !this.advancedFilter.CIE.includes(item.idCieRegistrato)
        ) {
          return false;
        }

        // Filtering based on ordine
        if (
          this.advancedFilter.ordine.length > 0 &&
          !this.advancedFilter.ordine.includes(item.idOrdineDiServizio)
        ) {
          return false;
        }

        // Filtering based on DaData and AData
        const itemDate = new Date(item.dataOraInizio);
        const daData = new Date(
          this.advancedFilter.DaData +
            "T" +
            this.advancedFilter.DaOra.HH +
            ":" +
            this.advancedFilter.DaOra.mm
        );
        const aData = new Date(
          this.advancedFilter.AData +
            "T" +
            this.advancedFilter.AOra.HH +
            ":" +
            this.advancedFilter.AOra.mm
        );

        if (itemDate < daData || itemDate > aData) {
          return false;
        }

        // Filtering based on stato
        if (
          this.advancedFilter.stato.length > 0 &&
          !this.advancedFilter.stato.includes(item.stato)
        ) {
          return false;
        }

        // Filtering based on luogoInizio
        if (
          this.advancedFilter.luogoInizio.length > 0 &&
          !this.advancedFilter.luogoInizio.includes(item.luogoInizio)
        ) {
          return false;
        }

        // Filtering based on indagato
        if (
          this.advancedFilter.indagato.length > 0 &&
          !this.advancedFilter.indagato.includes(item.indagato)
        ) {
          return false;
        }

        // If all filters pass, return true to keep the item
        return true;
      });

      // Use filteredItems for further processing or updating UI
      console.log("filteredItems", filteredItems);
      this.filtered_items = filteredItems;
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0;
    },

    computed_luoghi() {
      if (this.items.length == 0) {
        return [];
      }

      let result = [];

      for (let i = 0; i < this.items.length; i++) {
        if (!result.includes(this.items[i].luogoInizio)) {
          result.push(this.items[i].luogoInizio);
        }
      }

      return result;
    },
    computed_cieOptions() {
      if (this.items.length === 0) {
        return [];
      }

      let result = [];
      let encounteredIds = new Set();

      for (let i = 0; i < this.items.length; i++) {
        if (!encounteredIds.has(this.items[i].idCieRegistrato)) {
          result.push({
            value: this.items[i].idCieRegistrato,
            text: this.items[i].CieRegistrato,
          });
          encounteredIds.add(this.items[i].idCieRegistrato);
        }
      }

      return result;
    },

    computed_indagatoOptions() {
      if (this.items.length === 0) {
        return [];
      }

      let result = [];

      for (let i = 0; i < this.items.length; i++) {
        if (!result.includes(this.items[i].indagato)) {
          result.push(this.items[i].indagato);
        }
      }

      return result;
    },
    // ...mapGetters(["languages", "default_lang", "config"]),
    //     ...mapGetters(["default_lang"]),
    //     ...mapGetters(["config"]),
  },

  watch: {
    toggleVista() {
      this.$router.push(
        "/axerta/lista-rilevazioni-dettagliata/" + this.$route.params.idDossier
      );
    },
  },
};
</script>

<style>
.stato:hover {
  cursor: pointer;
}

.images {
  padding: 1rem 0;
}

img {
  border-radius: 5px;
}

.Pubblicazione {
  border: 4px solid green;
}

.Interna {
  border: 4px solid orange;
}

.Esclusa {
  border: 4px solid maroon;
  opacity: 0.5;
}

.DaAnalizzare {
  border: 4px solid purple;
}
</style>
